@use "styles/_fontSize.scss" as fontSize;

.footerWrapper {
  width: 100%;
  background-color: #32353b;
  padding: 10px 10px 0px 10px;
  position: fixed;
  bottom: 0px;
  z-index: 99;
  &--keyboardShown {
    display: none;
  }
}
.footerTxtWrapper {
  background-color: transparent;
  border-width: 0;
  padding: 0 5px;
}
.footerTxt {
  color: #fff;
  font-size: fontSize.$p;
  font-weight: 300;
}
.footerTxtAndIcons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.footerIconsWrapper {
  display: flex;
  flex-direction: row;
  width: 20%;
  justify-content: space-around;
  align-items: center;
}
.footerCompanyName {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding-top: 10px;
}

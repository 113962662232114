.container {
  height: 100%;
  display: flex;
  flex-direction: row;
  position: fixed;
  background-color: transparent;
  z-index: 100;
}

.leftPart {
  height: 100%;
  width: 60%;
}

.backdropSmall {
  height: 10%;
  width: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
}

.backdropNormal {
  height: 90%;
  width: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
}

.sideNav {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  left: 0;
  z-index: 1;

  .sideNavItemWrapper {
    background-color: #f1ecec;
    width: 100%;
    flex: 1;
    border-right: 1px solid #ddd;

    .sideNavItem {
      background-color: white;
      margin: 5px 0;
    }
  }
}

.rightPart {
  height: 100%;
  width: 40%;
}

.backdropLarge {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modal {
  width: 290px !important;
  min-height: 306px;
  border-radius: 5px;
  top: calc(50vh - 153px);
  padding: 0;
  .ant-modal-footer {
    border: none;
    text-align: center;
    padding: 20px;
  }
  .ant-modal-body {
    padding: 20px 18px;
    padding-bottom: 0;
    flex: 1;
    height: calc(100% - 73px);
  }
  .ant-modal-content {
    min-height: 306px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  overflow-x: scroll;
  padding: 10px 0;
  &--isWrapped {
    flex-wrap: wrap;
  }
}

.imgWrapper {
  position: relative;
}

.imgCont {
  border-radius: 6px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-width: 1px;
  border-color: black;
  border-style: solid;
}

.imgContClose {
  position: absolute;
  top: -10px;
  right: 0;
  z-index: 1;
}

.imgPlayPause {
  position: absolute;
  top: 30%;
  left: 30%;
  z-index: 1;
}

@use "styles/_fontSize.scss" as fontSize;

.container {
  font-family: "Helvetica Neue", sans-serif;
  margin-bottom: 15px;
  .label {
    font-size: fontSize.$h4;
    font-weight: bold;
  }
  .required {
    margin-left: 44px;
    color: #ff0000;
    font-size: fontSize.$h7;
  }
  .input {
    margin-top: 15px;
    :global {
      .ant-input {
        border: 1px solid #d2d2d2;
        border-radius: 10px;
        height: 45px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #707070;
      }
    }
    .ant-input-error {
      border: 1px solid red;
      border-radius: 10px;
      height: 45px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #707070;
    }
  }
}
.txtError {
  color: red;
  font-size: fontSize.$h8;
}

.avatarInfo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
  &--noBehindIcon {
    @extend .avatarInfo;
    margin-top: 0;
  }
  .avatar {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    top: 7px;
    &--noBehindIcon {
      @extend .avatar;
      top: 0;
      position: relative;
    }
  }
}

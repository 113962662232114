@use "styles/_fontSize.scss" as fontSize;

.container {
  display: flex;
  flex-direction: column;
  flex: auto;
  height: 90%;
}

.txtTitle {
  font-weight: 700;
  font-size: fontSize.$h2;
  margin-bottom: 30px;
}
.txtParagraph {
  font-size: fontSize.$h6;
}

.recipientsContainer {
  padding-bottom: 10px;
  :global {
    .infinite-scroll-component {
      overflow: initial !important;
    }
  }
}

.txtRecipient {
  font-size: 20px;
  font-weight: 700;
  padding-left: 20px;
}

.recipientsList {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  margin-top: 10px;
  overflow: auto;
  height: calc(100% - 80px);
  .recipientsListItem {
    display: flex !important;
    justify-content: space-between !important;
    padding: 15px;
  }
}

.contTxtName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60vw;
}

.spinLoader {
  margin-left: calc(50% - 18px);
  color: #a6e8da;
}

.btnCircleStatus {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d2d2d2;
  &--chosen {
    @extend .btnCircleStatus;
    border: 0;
    background-color: #f3530d;
  }
}

.receiverSearch {
  margin-top: 70px;
  margin-bottom: 10px;
  width: 100%;
  padding: 5px 10px;
  :global {
    .ant-input-affix-wrapper {
      border-radius: 16px;
      padding: 6px 11px;
    }
    .ant-input-prefix {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@use "styles/_fontSize.scss" as fontSize;

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 80px;
}

.contChat {
  padding: 0 15px;
  display: flex;
  flex-flow: column-reverse;
}

.itemChatTime {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: fontSize.$h6;
  margin: 15px 0 10px 0;
}

.itemChat {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &--sent {
    @extend .itemChat;
    align-items: flex-end;
  }
}

.itemAvaBubble {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  &--sent {
    @extend .itemAvaBubble;
    justify-content: flex-end;
  }
}

.itemBubble {
  border-radius: 20px;
  overflow: hidden;
  max-width: 250px;
  overflow-wrap: break-word;
  color: white;
  &--received {
    @extend .itemBubble;
    background-color: #625d5b;
  }
  &--sent {
    @extend .itemBubble;
    background-color: #f3530d;
  }
}

.contTxtBubble {
  padding: 8px 15px;
}

.contVideoBubble {
  width: 100%;
  margin-bottom: -10px;
}

.wrapperNameSeen {
  display: flex;
  justify-content: space-between;
  .txtName {
    font-weight: 700;
    margin-left: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 60vw;
  }
  .txtSeen {
    color: #625d5b;
  }
}

.inputChatWrapper {
  width: 100%;
  padding: 20px 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btnAction {
    padding: 5px;
  }
  .input {
    border-radius: 15px;
    margin: 0 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.uploadCustom {
  :global {
    .ant-upload.ant-upload-select-picture-card {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-bottom: 0;
      background-color: transparent;
      border: 0;
    }
  }
}

.itemsListContainer {
  :global {
    .ant-list-items {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.itemMessage {
  padding: 0;
}

.txtError {
  color: red;
}

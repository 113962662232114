.btnCircle {
  width: 62px;
  height: 62px;
  background-color: #d2d2d2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &--default {
    width: 120px;
    height: 120px;
    img {
      width: auto !important;
      height: 41px !important;
      object-fit: cover;
    }
  }
  &--large {
    width: 163px;
    height: 163px;
    img {
      width: 163px !important;
      height: 163px !important;
      object-fit: cover;
    }
  }
  &--disabled {
    background-color: #f4f4f4;
  }
  img {
    height: 21px;
    width: auto;
    border-radius: 50%;
    object-fit: cover;
  }
  .imgContent {
    height: 180px !important;
    width: 180px !important;
    border-radius: 100%;
    position: absolute;
  }
}

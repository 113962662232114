.text-ali-center {
  text-align: center;
}

.d-flex {
  display: flex !important;
}

.ali-item-start {
  align-items: flex-start;
}

.ali-item-center {
  align-items: center;
}

.mt-15 {
  margin-top: 15px;
}

.mr-30 {
  margin-right: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.pt-1 {
  padding-top: 1px !important;
}

.img-border {
  width: 83px !important;
  height: 44px !important;
  border-radius: 14px;
  background-color: #F14D11;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-none {
  display: none !important;
}

.dateStr {
  font-size: 12px;
  min-width: 100px;
  text-align: right;
}

//alert create
.alert-create-modal, .alert-cancel-modal {
  .ant-modal-content {
    height: 306px;
  }
  .alert-create-content, .alert-cancel-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .alert-create-text, .alert-cancel-text {
      margin: 0 11px;
      text-align: center;
    }
  }
}
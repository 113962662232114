.btn {
  font-size: 11px;
  border: none;
  color: #fff;
  background-color: #f14d11;
  min-width: 69px;
  height: 33px;
  border-radius: 19px;
  font-weight: bold;
  box-shadow: none;
  &:hover,
  &:focus {
    border: none;
    color: #fff;
    background-color: #f14d11;
  }
  &--secondary {
    color: #000;
    border: 1px solid #707070;
    background-color: #fff;
    min-width: 106px;
    width: auto;
    &:hover,
    &:focus {
      border: 1px solid #707070;
      background-color: #fff;
      color: #3c3c3c;
    }
  }
  &--chat {
    color: #000;
    background-color: #6be9e7;
    min-width: 106px;
    width: auto;
    &:hover,
    &:focus {
      background-color: #6be9e7;
      color: #000;
    }
  }
  &--low {
    width: 191px;
    height: 48px;
    border-radius: 10px;
    font-size: 16px;
  }
  &--icon {
    width: 255px;
    height: 88px;
    border: 1px solid #d2d2d2 !important;
    background-color: #fff !important;
    color: #3c3c3c !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:active {
      border: none;
      background-color: #f56738 !important;
      color: #fff;
    }
    img {
      margin-bottom: 12px;
    }
  }
  &--none {
    border: none;
    background-color: #fff;
    color: #707070;
    font-size: 16px;
    width: 106px;
    &:hover,
    &:focus {
      border: none;
      background-color: #fff;
      color: #707070;
      box-shadow: none;
      outline: none;
    }
  }
  &--active {
    border: none !important;
    background-color: #f56738 !important;
    color: #fff !important;
  }
  &--non-active {
    &:hover {
      border: 1px solid #707070;
      background-color: #fff;
      color: #3c3c3c;
    }
  }
}

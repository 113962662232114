.btnNextWrapperAbsolute {
  position: fixed;
  z-index: 2;
  bottom: 150px;
  right: 20px;
}

.btnNextWrapperRelative {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 30px;
}

.tab-custom {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #3c3c3c;
    font-weight: 200;
  }
  .ant-tabs-tab-btn {
    color: #707070;
    font-weight: 200;
  }
  .ant-tabs-ink-bar {
    background: #3c3c3c;
  }
}
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav,
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav {
  margin: 0 0 8px;
}
.ant-tabs-bottom > .ant-tabs-nav:before,
.ant-tabs-bottom > div > .ant-tabs-nav:before,
.ant-tabs-top > .ant-tabs-nav:before,
.ant-tabs-top > div > .ant-tabs-nav:before {
  border-bottom: 0;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
  width: 70px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #f0f0f0;
}

@use "styles/_fontSize.scss" as fontSize;

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  position: fixed;
  top: 0px;
  z-index: 99;
  &--shadow {
    box-shadow: 0 3px 3px 0 #ddd;
    border-bottom-width: 1px;
    border-bottom-color: #ddd;
  }
}

.btnActionWrapper {
  padding: 5px;
  &--hasRightComponent {
    margin-left: 20px;
  }
}

.divEmpty {
  width: 30px;
  height: 30px;
}

.txtTitle {
  font-size: fontSize.$h3;
  font-weight: 700;
}

@use "styles/_fontSize.scss" as fontSize;

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.txtTitle {
  font-weight: 700;
  font-size: fontSize.$h2;
  margin-bottom: 30px;
}
.txtParagraph {
  font-size: fontSize.$h6;
}

.contentContainer {
  padding: 80px 20px 0px 20px;
}

.label {
  font-size: fontSize.$h4;
  font-weight: bold;
}

.required {
  margin-left: 44px;
  color: #ff0000;
  font-size: fontSize.$h7;
}

.btnAvaWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@use "styles/_fontSize.scss" as fontSize;

.container {
  display: flex;
  flex-direction: column;
  flex: auto;
  height: 90%;
}

.txtParagraph {
  font-size: fontSize.$h6;
}

.txtSenderName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60vw;
  &--unseen {
    @extend .txtSenderName;
    font-weight: 700;
    color: black;
  }
}

.txtContent {
  font-size: fontSize.$h7;
  color: #a7a7a7;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 35vw;
  &--unseen {
    @extend .txtContent;
    font-weight: 700;
    color: black;
  }
}

.conversationSearch {
  margin-top: 70px;
  margin-bottom: 10px;
  width: 100%;
  padding: 5px 10px;
  :global {
    .ant-input-affix-wrapper {
      border-radius: 16px;
      padding: 6px 11px;
    }
    .ant-input-prefix {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.recipientsContainer {
  padding-bottom: 10px;
  :global {
    .infinite-scroll-component {
      overflow: initial !important;
    }
  }
}

.recipientsList {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  overflow: auto;
  height: calc(100% - 80px);
  &--noData {
    @extend .recipientsList;
    border-top: 0;
    border-bottom: 0;
  }
  .recipientsListItem {
    display: flex !important;
    justify-content: space-between !important;
    padding: 15px;
  }
}

.spinLoader {
  margin-left: calc(50% - 18px);
  color: #a6e8da;
}

.btnCircleStatus {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 0;
  background-color: #f3530d;
}

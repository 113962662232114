@use "styles/_fontSize.scss" as fontSize;

.container {
  padding: 80px 0 0 0;
}

.contentContainer {
  padding: 0 20px 220px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.termItemWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.titleWrapper {
  margin: 30px 0;
  display: flex;
  width: 100%;
  justify-content: center;
}

.contentWrapper {
  display: flex;
  width: 100%;
  word-break: break-word;
}

.txtContent {
  font-weight: 500;
  font-size: fontSize.$h6;
}
